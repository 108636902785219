<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b"><el-button type="primary" size="small" @click="addUser"
						v-right-code="'Sysuserclient:Save'">新增</el-button> </div>
				<div class="h-b"><el-button type="text" size="small" @click="saveUser" v-loading="loadding"
						v-right-code="'Sysuserclient:Save'">保存</el-button> </div>
			</div>
		</div>
		<div class="form-list">
			<el-form ref="_userInfoForm" :model="dataSource" :rules="userCheckRule">
				<el-form-item>
					<el-col :span="3" class="form-title">员工ID：</el-col>
					<el-col :span="7">
						<el-form-item>
							<wl-look-popup v-model="dataSource.StaffCode" dataTitle="员工选择"
								dataUrl="omsapi/sysstaff/getpagelist" syncField='Code'
								@syncFieldEvent="syncStaffCodeEvent">
							</wl-look-popup>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>用户姓名：</el-col>
					<el-col :span="7">
						<el-form-item prop="RealName">
							<el-input v-model.trim="dataSource.RealName" :maxlength="30" :minlength="4"
								:disabled="isDisabled" :readonly="isDisabled" placeholder="登录账号"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">性别：</el-col>
					<el-col :span="7">
						<el-form-item prop="Gender">
							<DictionarySelect v-model="dataSource.Gender" dicTypeCode="SexTypeCode" datType="Int"></DictionarySelect>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">工号：</el-col>
					<el-col :span="7">
						<el-form-item>
							<el-input v-model.trim="dataSource.JobNumber" :maxlength="50" placeholder="工号"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>登录账号：</el-col>
					<el-col :span="7">
						<el-form-item prop="UserName">
							<el-input v-model.trim="dataSource.UserName" :maxlength="30" :minlength="4"
								:disabled="isDisabled" :readonly="isDisabled" placeholder="登录账号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>用户类别：</el-col>
					<el-col :span="7">
						<el-form-item prop="PersonType">
							<DictionarySelect v-model="dataSource.PersonType" dicTypeCode="PersonTypeCode" datType="Int" @change="personTypeChange"></DictionarySelect>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col v-if="dataSource.PersonType!=null" :span="3" class="form-title">{{personTypeName}}</el-col>
					<el-col v-if="dataSource.PersonType!=null" :span="7">
						<el-form-item prop="PersonType">
							<LookPopup v-if="dataSource.PersonType*1==3" v-model="dataSource.RelationName"
								dataTitle="客商选择"
								dataUrl='omsapi/customervendor/getlist'
								syncField='VendorName'
								@syncFieldEvent="syncPersonTypeEvent"
							></LookPopup>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item v-if="!dataSource.UserId">
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>登录密码：</el-col>
					<el-col :span="7">
						<el-form-item prop="UserPassword">
							<el-input type="password" v-model="dataSource.UserPassword" :maxlength="32" :minlength="8"
								placeholder="密码"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>确认密码：</el-col>
					<el-col :span="7">
						<el-form-item prop="ReUserPassword">
							<el-input type="password" v-model="dataSource.ReUserPassword" :maxlength="32" :minlength="8"
								placeholder="确认密码"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">邮箱：</el-col>
					<el-col :span="7">
						<el-form-item>
							<el-input v-model.trim="dataSource.Email" :maxlength="100" placeholder="邮箱"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>联系电话：</el-col>
					<el-col :span="7">
						<el-form-item prop="Mobile">
							<el-input v-model.number="dataSource.Mobile" :maxlength="11" placeholder="联系电话"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">状态：</el-col>
					<el-col :span="7">
						<el-col :span="7">
							<el-form-item>
								<el-select v-model="dataSource.UserStatus" :maxlength="200">
									<el-option v-for="item in UserStatusList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-col>
					<!--<el-col :span="3" class="form-title">后台权限过滤：</el-col>
                    <el-col :span="7">
                        <el-form-item prop="IsRightFilter">
                            <el-switch v-model="dataSource.IsRightFilter" :disabled="dataSource.IsSuperAdmin" :on-value="true" :off-value="false" on-text="是" off-text="否"></el-switch>
                        </el-form-item>
                    </el-col>-->
				</el-form-item>
			</el-form>
		</div>

		<el-tabs type="border-card" v-model="tabActiveName">
			<el-tab-pane label="角色授权" name="tabRole">
				<div class="form-padding h-handle-table h-table-list">
				<div class="table-s">
					<el-table v-loading="loadding" ref="_roleList" :data="dataSource.Roles"
						:default-sort="{ prop: 'RoleName' }" style="width: 100%" border
						@selection-change="handleSelectionChange" highlight-current-row>
						<el-table-column type="selection" width="55">
							<template slot-scope="scope">
								<el-checkbox v-model="scope.row.Checked"></el-checkbox>
							</template>
						</el-table-column>
						<el-table-column prop="RoleName" label="角色名称"></el-table-column>
						<el-table-column prop="IsSystemRole" label="系统角色">
							<template slot-scope="scope">
								<el-tag :type="scope.row.IsSystemRole ? 'success' : 'primary'" close-transition
									v-text="scope.row.IsSystemRole === true ? '是' : '否'"></el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="RoleDesc" label="描述"></el-table-column>
					</el-table>
				</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="物流组织" name="tabOrg" v-if="dataSource.PersonType==1">
				<div class="form-padding h-handle-table h-table-list h-handle-bg">
					<div class="h-handle-button">
						<el-button type="text" size="small" @click="orgControlShow(0)">新增</el-button>
					</div>
					<div class="table-s">
						<el-tabs v-model="tabSubActiveName">
							<el-tab-pane label="组织机构" name="tabOrg">
								<el-table v-loading="loadding" ref="_orgList" :data="dataSource.OrgList" style="width: 100%" border highlight-current-row>
									<el-table-column type="index" width="55" label="序号"></el-table-column>
									<el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
									<el-table-column prop="OrgName" label="组织机构名称"></el-table-column>
									<el-table-column prop="DataType" label="操作">
										<template slot-scope="scope">
											<el-button @click="dataSource.OrgList.splice(scope.$index, 1)">移除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane label="货主" name="tabCustomer">
								<el-table v-loading="loadding" ref="_customerList" :data="dataSource.CustomerList" style="width: 100%" border highlight-current-row>
									<el-table-column type="index" width="55" label="序号"></el-table-column>
									<!-- <el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
									<el-table-column prop="OrgName" label="组织机构名称"></el-table-column> -->
									<el-table-column prop="CarrierCode" label="货主编码"></el-table-column>
									<el-table-column prop="CarrierName" label="货主名称"></el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<el-button @click="dataSource.CustomerList.splice(scope.$index, 1)">移除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
							<el-tab-pane label="承运商" name="tabCarrier">
								<el-table v-loading="loadding" ref="_carrierList" :data="dataSource.CarrierList" style="width: 100%" border highlight-current-row>
									<el-table-column type="index" width="55" label="序号"></el-table-column>
									<!-- <el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
									<el-table-column prop="OrgName" label="组织机构名称"></el-table-column> -->
									<el-table-column prop="CarrierCode" label="承运商编码"></el-table-column>
									<el-table-column prop="CarrierName" label="承运商名称"></el-table-column>
									<el-table-column prop="DataType" label="操作">
										<template slot-scope="scope">
											<el-button @click="dataSource.CarrierList.splice(scope.$index, 1)">移除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="项目授权" name="tabProject" v-if="dataSource.PersonType==1">
				<div class="form-padding h-handle-table h-table-list h-handle-bg">
					<div class="h-handle-button">
						<el-button type="text" size="small" @click="orgControlShow(1)">新增</el-button>
					</div>
					<div class="table-s">
						<el-table v-loading="loadding" ref="_projectList" :data="dataSource.ProjectList" style="width: 100%" border highlight-current-row>
							<el-table-column type="index" width="55" label="序号"></el-table-column>
							<el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
							<el-table-column prop="OrgName" label="组织机构名称"></el-table-column>
							<el-table-column prop="ProjectCode" label="项目编码"></el-table-column>
							<el-table-column prop="ProjectName" label="项目名称"></el-table-column>
							<el-table-column prop="DataType" label="操作">
								<template slot-scope="scope">
									<el-button @click="dataSource.ProjectList.splice(scope.$index, 1)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="货主授权" name="tabCustomer" v-if="dataSource.PersonType==2">
				<div class="form-padding h-handle-table h-table-list h-handle-bg">
					<div class="h-handle-button">
						<el-button type="text" size="small" @click="orgControlShow(2)">新增</el-button>
					</div>
					<div class="table-s">
						<el-table v-loading="loadding" ref="_customerList" :data="dataSource.CustomerList" style="width: 100%" border highlight-current-row>
							<el-table-column type="index" width="55" label="序号"></el-table-column>
							<!-- <el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
							<el-table-column prop="OrgName" label="组织机构名称"></el-table-column> -->
							<el-table-column prop="CarrierCode" label="货主编码"></el-table-column>
							<el-table-column prop="CarrierName" label="货主名称"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button @click="dataSource.CustomerList.splice(scope.$index, 1)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="承运商授权" name="tabCarrier"  v-if="dataSource.PersonType==5">
				<div class="form-padding h-handle-table h-table-list h-handle-bg">
					<div class="h-handle-button">
						<el-button type="text" size="small" @click="orgControlShow(3)">新增</el-button>
					</div>
					<div class="table-s">
						<el-table v-loading="loadding" ref="_carrierList" :data="dataSource.CarrierList" style="width: 100%" border highlight-current-row>
							<el-table-column type="index" width="55" label="序号"></el-table-column>
							<!-- <el-table-column prop="OrgCode" label="组织机构编码"></el-table-column>
							<el-table-column prop="OrgName" label="组织机构名称"></el-table-column> -->
							<el-table-column prop="CarrierCode" label="承运商编码"></el-table-column>
							<el-table-column prop="CarrierName" label="承运商名称"></el-table-column>
							<el-table-column prop="DataType" label="操作">
								<template slot-scope="scope">
									<el-button @click="dataSource.CarrierList.splice(scope.$index, 1)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-tab-pane>
			<!-- <el-tab-pane label="仓库授权" name="tabWarehouse"></el-tab-pane> -->
		</el-tabs>
		
		<DialogEx :options="controlConfig.viewConfig" title="组织机构" @onOk="onOrgControlOk">
			<OrgControl ref="orgControl" :config="controlConfig.dataConfig" @onSelectedRow="onOrgControlSelectedRow">
			</OrgControl>
		</DialogEx>
	</div>
</template>

<script>
	export default {
		data() {
			var checkUsername = function(rule, value, callback) {
				if (!value) return callback(new Error('登录账号不能为空'));
				callback();
			};
			var realname = function(rule, value, callback) {
				if (!value) return callback(new Error('姓名不能为空'));
				callback();
			};
			var nickname = function(rule, value, callback) {
				if (!value) return callback(new Error('昵称不能为空'));
				callback();
			};
			var checkPassword = (rule, value, callback) => {
				if (!this.isDisabled) {
					if (value === '' || !value) callback(new Error('请输入密码'))
					else {
						if (value.length < 8) {
							callback(new Error('密码至少八位或者八位以上'))
						}
						var patrn =
							/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
						if (!patrn.exec(value)) callback(new Error('密码必须包含数字，字母，特殊字符'))
						if (this.dataSource.ReUserPassword !== '') {
							this.$refs._userInfoForm.validateField('ReUserPassword');
						}
						callback();
					}
				} else callback();
			};

			var checkRepassword = (rule, value, callback) => {
				if (!this.isDisabled) {
					if (value === '' || !value) {
						callback(new Error('请再次输入密码'));
					} else if (value !== this.dataSource.UserPassword) {
						callback(new Error('两次输入密码不一致!'));
					} else {
						callback();
					}
				} else callback();
			};

			var checkMobile = (rule, value, callback) => {
				if (!value) return callback(new Error('联系电话不能为空'));
				if (void 0 == value || !value || value.length == 0)
					callback();
				else {
					if (!this.Utils.isMobile(value))
						callback(new Error('联系电话无效.'));
					else
						callback();
				}
			};
			return {
				multipleSelection: [],
				personTypeName:null,
				reUserPassword: '',
				dataSourceCopy: {},
				loadding: false,
				isDisabled: false,
				tabActiveName: 'tabRole',
				tabSubActiveName:'tabOrg',
				currentEnterpriseSelectedRows: [],
				enterpriseOptions: {
					visible: false,
					size: 'small'
				},
				enterpriseConfig: {
					isDetailDisplay: true,
					isSelectionDisplay: true,
				},
				controlConfig: {
                    viewConfig:{
                        visible: false,
                        size: 'normal',
						customClass: 'controlDialog'
                    },
					dataConfig:{
						dataType:1,
					}
                },
				UserStatusList: [{
						label: '正常',
						value: "100"
					},
					{
						label: '停用',
						value: "400"
					}
				],
				userCheckRule: {
					UserName: [{
						validator: checkUsername,
						trigger: 'blur'
					}],
					UserPassword: [{
						validator: checkPassword,
						trigger: 'blur'
					}],
					ReUserPassword: [{
						validator: checkRepassword,
						trigger: 'blur'
					}],
					Mobile: [{
						validator: checkMobile,
						trigger: 'blur'
					}],
					RealName: [{
						validator: realname,
						trigger: 'blur'
					}],
					SysBizType: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'blur'
					}]
				},
				sysBizTypeList: [{
						label: '系统',
						value: 100
					},
					{
						label: '业务',
						value: 200
					}
				],
				treeProps: {
                    children: 'Children',
                    label: 'Name',
					disabled:'Disabled'
                },
				orgTree:[],
				checkedOrgKeys:[],
				projectTree:[],
				checkedProjectKeys:[],
				ownerTree:[],
				checkedOwnerKeys:[],
				carrierTree:[],
				checkedCarrierKeys:[],
				customerTree:[],
				checkedCustomerKeys:[],
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
			},
			dataSource: {
			}
		},
		mounted() {
			this.Event.$on("clearEditUserForm", () => this.resetForm);
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					if(curVal.UserId!=oldVal.UserId){
						this.tabActiveName = 'tabRole';
					}
				},
				deep: true
			},
			filterText(val) {
				this.$refs.customTree.$refs.tree.filter(val);
			}
		},
		methods: {
			personTypeChange(val){
				console.log('11',val)
				//1.组织；2.货主；3.客户；4.供应商；5.承运商；
				if(val*1==1){
					this.personTypeName="";
					this.dataSource.RelationId = null;
					this.dataSource.RelationName=null;
				}else if(val*1==2){
					this.personTypeName="";
					this.dataSource.OrgList = [];
					this.dataSource.ProjectList = [];
					this.dataSource.CarrierList = [];
					this.dataSource.WarehouseList = [];
					this.dataSource.RelationId = null;
					this.dataSource.RelationName=null;
				}else if(val*1==3){
					this.personTypeName="关联客户：";
					this.dataSource.OrgList = [];
					this.dataSource.ProjectList = [];
					this.dataSource.CustomerList = [];
					this.dataSource.CarrierList = [];
					this.dataSource.WarehouseList = [];
				}else if(val*1==4){
					this.personTypeName="";
					this.dataSource.OrgList = [];
					this.dataSource.ProjectList = [];
					this.dataSource.CustomerList = [];
					this.dataSource.CarrierList = [];
					this.dataSource.WarehouseList = [];
					this.dataSource.RelationId = null;
					this.dataSource.RelationName=null;
				}else if(val*1==5){
					this.personTypeName="";
					this.dataSource.OrgList = [];
					this.dataSource.ProjectList = [];
					this.dataSource.CustomerList = [];
					this.dataSource.WarehouseList = [];
					this.dataSource.RelationId = null;
					this.dataSource.RelationName=null;
				}
			},
			syncPersonTypeEvent:function(row){
				if(row){
					if(this.dataSource.PersonType*1==1){
						this.dataSource.RelationId = row.Id;
						this.dataSource.RelationName=row.Name;
					}else if(this.dataSource.PersonType*1==2){
						this.dataSource.RelationId = row.CustomerID;
						this.dataSource.RelationName=row.CustomerName;
					}else{
						this.dataSource.RelationId = row.VendorId;
						this.dataSource.RelationName=row.VendorName;
						this.dataSource.RelationCode=row.VendorCode;
					}
					console.log(this.dataSource.RelationName)
                }
			},
			tab2Click: function(tab, event) {},
			handleSelectionChange(val) {

				if (val.length > 0)
					val.forEach((item, index) => item.Checked = true);
				else
					this.dataSource.Roles.forEach((item, index) => item.Checked = false);
			},
			addUser() {
				this.tabActiveName = 'tabRole';
				this.Event.$emit("onAddUser");
			},
			resetForm() {
				!this.isPassValidate && this.$refs['_userInfoForm'].resetFields(); //清空表单
			},
			//tab页-客户管理操作
			searchEnterpriseList: function() {
				this.enterpriseOptions.visible = true;
				//this.Event.$emit("refreshSelectGroupPositionForm");
			},
			onEnterpriseSelectedRow: function(data) {
				this.currentEnterpriseSelectedRows = data;
			},
			onEnterpriseOk: function() {
				var _this = this;
				var rows = _this.currentEnterpriseSelectedRows;
				if (!rows || rows.length == 0) return;
				if (!this.dataSource.EnterpriseInfos) {
					this.dataSource.EnterpriseInfos = [];
				}
				for (var i = 0; i < rows.length; i++) {
					var item = rows[i];
					var newRow = {
						OptEnterpriseID: item.OptEnterpriseID,
						EnterpriseCode: item.EnterpriseCode,
						EnterpriseName: item.EnterpriseName,
					};
					_this.dataSource.EnterpriseInfos.push(newRow);
				}
				//合并两个数组
				// arr1.push(...arr2)//或者arr1 = [...arr1,...arr2]

				//let arr3 = [...new Set(arr1)]
				_this.dataSource.EnterpriseInfos = _this.uniqueArrEnterprise(_this.dataSource.EnterpriseInfos);
				console.log(" _this.dataSource.EnterpriseInfos:", _this.dataSource.EnterpriseInfos);
			},
			removeEnterprise: function(row, index) {
				this.dataSource.EnterpriseInfos.remove(row);
			},
			////客户选择去重
			uniqueArrEnterprise: function(array) {
				var r = [];
				for (var i = 0, l = array.length; i < l; i++) {
					for (var j = i + 1; j < l; j++)
						if (array[i].EnterpriseCode === array[j].EnterpriseCode) j = ++i;
					r.push(array[i]);
				}
				return r;
			},
			//保存
			saveUser() {
				var _this = this;
				_this.$refs["_userInfoForm"].validate((valid) => {
					_this.isPassValidate = valid;
					if (valid) {
						_this.$ajax.send("omsapi/sysuser/save", "post", _this.dataSource, (data) => {
							debugger;
							if(data.IsSuccess){
								_this.$parent.syncDataSource(data.Result);
								_this.Event.$emit(_this.config.moduleEvent.initial, data.Result);
							}
							_this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						return false;
					}
				});
			},
			orgControlShow(dataType){
				this.controlConfig.viewConfig.visible=true;
				this.controlConfig.dataConfig.dataType=dataType;
			},
			onOrgControlOk(){
				var data = this.$refs.orgControl.getSelectedAll();
				var dataType = this.controlConfig.dataConfig.dataType;
				debugger
				if(dataType==0){
					this.dataSource.OrgList = this.Utils.arrayUnion([this.dataSource.OrgList,data.OrgList],'OrgId');
					this.dataSource.CustomerList = this.Utils.arrayUnion([this.dataSource.CustomerList,data.CustomerList],'CustomerId');
					this.dataSource.CarrierList = this.Utils.arrayUnion([this.dataSource.CarrierList,data.CarrierList],'CarrierId');
					this.dataSource.WarehouseList = this.Utils.arrayUnion([this.dataSource.WarehouseList,data.WarehouseList],'WarehouseId');
				}else if(dataType==1){
					this.dataSource.ProjectList = this.Utils.arrayUnion([this.dataSource.ProjectList,data.ProjectList],'ProjectId');
				}else if(dataType==2){
					this.dataSource.CustomerList = this.Utils.arrayUnion([this.dataSource.CustomerList,data.CustomerList],'CustomerId');
				}else if(dataType==3){
					this.dataSource.CarrierList = this.Utils.arrayUnion([this.dataSource.CarrierList,data.CarrierList],'CarrierId');
				}
			},
			onOrgControlSelectedRow(){
			},
			syncStaffCodeEvent(row){
				if(row){
					this.dataSource.RealName = row.Name;
					this.dataSource.Gender = row.Sex;
					this.dataSource.JobNumber = row.JobNumber;
					this.dataSource.Mobile = row.Phone;
					this.dataSource.Email = row.Email;
					if(!this.dataSource.UserName){
						this.dataSource.UserName = row.Phone;
					}
				}
			}
		},
		components: {
			"Enterprise": resolve => {
				require(['@/components/business/selector/enterpriseselector.vue'], resolve)
			},
			"OrgControl": resolve => {
				require(['../../components/orgcontrol.vue'], resolve)
			}
		}
	}
</script>

<style>
   .org-2{ color: cornflowerblue;}
   .org-null{ color:red;}
   .custom-tree-nodeicon{
	padding: 0 5px;
    border-radius: 5px;
    color: #fff;
	background-color: #0fbabf;
   }
   .controlDialog .h-full{
	  min-height: 300px;
   }
</style>